.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

table > tbody > tr > td:nth-child(2) {
  white-space: nowrap;
  overflow: hidden;
}

.github-picker {
  z-index: 999;
  position: absolute !important;
}

.ant-form-item {
  margin: 0 0 16px;
}

.ant-table-tbody > tr > td {
  word-break: break-all;
  max-width: 120px;
}

.App-link {
  color: #61dafb;
}

.ant-spin {
  /* margin: auto; */
  height: calc(100vh - 180px);
}

.ant-spin-spinning {
  padding: 20%;
}

.ant-typography.ant-typography-disabled {
  user-select: auto;
  cursor: auto;
}

.ant-modal-confirm-body
  > .anticon
  + .ant-modal-confirm-title
  + .ant-modal-confirm-content {
  margin-left: 0px !important;
}

h5.ant-typography {
  line-height: 40px;
}

#remoteStorage tbody > tr > td:nth-child(3) {
  text-transform: uppercase;
}

.ant-collapse-header {
  padding: 8px 10px !important;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 2px !important;
}

#passTrue,
#passFalse {
  display: none;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 55px;
  margin: 16px;
  background: url("./gar_white.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.logo-dark {
  background: url("./gar_black.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
.ant-menu-inline.ant-menu-root
  .ant-menu-submenu-title
  > .ant-menu-title-content {
  font-weight: 500;
}

.form-login {
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 10px 30px !important;
  background: #fff;
}
#normal-login .login-form-forgot {
  float: right;
}
#normal-login .ant-col-rtl .login-form-forgot {
  float: left;
}
#normal-login .login-form-button {
  width: 100%;
}
